import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { B2B_PATH } from '../../constants';
import Button from '../Clickable/Clickable';
import { headerMessages as messages } from '../../defineMessages';
import Link from '../Link/Link';
import Label from '../Label/Label';

const BusinessButton = ({ intl, ...props }) => {
  const businessButtonLink = intl.formatMessage({
    ...messages.businessButtonLink,
  });

  return (
    <Button
      tertiary
      {...props}
      style={{ backgroundColor: '#fb48c4', color: '#FFF' }}
      link={
        <Link
          unprefixed={businessButtonLink !== B2B_PATH}
          to={businessButtonLink}
        />
      }
      label={
        <Label>
          {intl.formatMessage({
            ...messages.business,
          })}
        </Label>
      }
    />
  );
};

BusinessButton.propTypes = {
  intl: PropTypes.shape().isRequired,
};

export default compose(injectIntl)(BusinessButton);
